import React from 'react'

import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

import CountdownFlipClock from '../components/countdownflipclock'
import Logo from '../components/logo'

const BackgroundSection = ({ className }) => (
  <StaticQuery query={graphql`
      query {
        site {
          siteMetadata {
            title,
            tagLine
          }
        }
        desktop: file(relativePath: { eq: "tortue-background_02.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
  render={data => {
    // Set ImageData.
    const imageData = data.desktop.childImageSharp.fluid
    const departureDate = new Date('Sept 21 2019 9:00:00 EDT')

    return (
      <Container>
        <TortueLogo>
          <Logo></Logo>
        </TortueLogo>
        <BackgroundContainer>

          <ImgCont>
            <BackgroundImage Tag="div"
              className={className}
              fluid={imageData}
              backgroundColor={`#040e18`}
              preserveStackingContext={true}
            >
            </BackgroundImage>
          </ImgCont>

          <TitleContainer>

            <h2>{data.site.siteMetadata.tagLine}</h2>
            <CountdownFlipClock departureDate={departureDate}></CountdownFlipClock>
          </TitleContainer>
        </BackgroundContainer>
      </Container>
    )
  }
  }
  />
)

const Container = styled.div`

`

const TortueLogo = styled.div`
  position: absolute;
  z-index: 1;
`

const IndexPage = styled(BackgroundSection)`
  width: 100%;
  height: 100vh;
  :after{
    opacity: 0.5;
    z-index: -1; 
  }
  `

const ImgCont = styled.div`
  postition: absolute;
  width: 100%;
  height: 100vh;
  background-color: black;
  opacity: 0.4;
`

const BackgroundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`

const TitleContainer = styled.div`
  color: black;
  width:100%;
  text-align: center;
  position: absolute;
  background-color: #619f9f;
  min-height: 12em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
`

export default IndexPage
