import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      placeholderImage: file(relativePath: { eq: "cinqtortue-icon_02.png" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <LogoContainer>
      <LogoImageContainer>
        <Img
          fixed={data.placeholderImage.childImageSharp.fixed}
          alt='cinqtortues.ca'
        />
      </LogoImageContainer>
      <Title>{data.site.siteMetadata.title}</Title>
    </LogoContainer>
  )
}

const LogoImageContainer = styled.div`  
  float: left;
  display: inline-block;
  transform: translateY(10px);
`

const LogoContainer = styled.div`
  width: 100%;
  margin-left: 10px;
`

const Title = styled.h1`
  display: inline-block;
  margin-top:15px;
  margin-left:5px;
`

export default Logo
