import React from 'react'
import FlipClock from 'flipclock'
import './countdownflipclock.css'

class CountdownFlipClock extends React.Component {
  constructor (props) {
    super(props)
    let clock = null
  }

  render () {
    return <div style={{ marginTop: '2em' }}><div id='clock'></div></div>
  }

  componentDidMount () {
    // Instantiate a coutdown FlipClock component
    this.clock = new FlipClock(document.getElementById('clock'), this.props.departureDate, {
      face: 'DayCounter',
      countdown: true
    })
  }
}

export default CountdownFlipClock
